import React from "react";
import "../styles/_base.scss"
import Head from "./Head"
import Header from "./Header"
import Footer from "./Footer"
import CTA from "./CTA"
import CookieBanner from "./CookieBanner"

export default ({ children, ...props }) => {
    return (
        <>
            <Head {...props} />
            {/* <script
              dangerouslySetInnerHTML={{
                __html: `
                Userback = window.Userback || {};
                Userback.access_token = '11212|23328|RWAgqvLsg5M7cSEQB7RuiCQ8R';
                (function(id) {
                    var s = document.createElement('script');
                    s.async = 1;s.src = 'https://static.userback.io/widget/v1.js';
                    var parent_node = document.head || document.body;parent_node.appendChild(s);
                })('userback-sdk');
                    `,
              }}
            /> */}
            <Header {...props} />
            <main className={ `page${props.pageType}` }>
                {children}
            </main>
            <CookieBanner {...props} />
            <CTA {...props} />
            <Footer {...props} />
        </>
    )
}