import React from "react"
import Grid from "@material-ui/core/Grid"
import Collapse from "@material-ui/core/Collapse"
import List from "@material-ui/core/List"
import IconButton from "@material-ui/core/IconButton"
import Hidden from "@material-ui/core/Hidden"
import Close from "@material-ui/icons/Close"
import Logo from "./Logo"
import Page from "./Link"
import NavigationDropdown, { SubNavigation, DropdownItem } from "./NavigationDropdown"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    root: {
        '&:last-child': {
            paddingRight: `0`
        }
    },
    link: {
        padding: `0 10px`,
    },
    dropdown: {
        padding: `0 5px 0 10px`,
    },
    container: {
        maxHeight: "90vh",
        overflow: "scroll",
    }

}))

const Nav = (props) => {
    const classes = useStyles()
    return (
        <>
            {
                props.navigation.map((item, i) => {
                    if (item.__typename.replace("Contentful", "") === "NavigationDropdown") {
                        return <Grid className={`${classes.root} ${classes.dropdown}`} key={i} item xs="auto"><NavigationDropdown key={i} {...item}></NavigationDropdown></Grid>
                    }
                    return <Grid className={`${classes.root} ${classes.link}`} key={i} item xs="auto"><Page {...item} /></Grid>
                })
            }
        </>
    )
}

const NavMobile = (props) => {
    const classes = useStyles()
    return (
        <Collapse in={props.open} timeout="auto" unmountOnExit classes={{ container: classes.container }}>
            <List disablePadding>
                {
                    props.navigation.map((item, i) => {
                        if (item.__typename.replace("Contentful", "") === "NavigationDropdown") {
                            return <SubNavigation desktop={false} key={i} {...item}></SubNavigation>
                        }
                        return <DropdownItem key={i} {...item} />
                    })
                }
            </List>
        </Collapse>
    )
}

export default (props) => {
    const [open, setOpen] = React.useState()
    const handleClick = (event) => {
        setOpen(!open)
    }
    return (
        <>
            <Grid component="nav" id="nav" container justify="space-between" alignItems="center" alignContent="center">
                <Grid item xs="auto">
                    <Logo {...props} />
                </Grid>
                {/* desktop */}
                <Grid item xs="auto">
                    <Grid container justify="center" alignItems="baseline" alignContent="baseline">
                        <Hidden smDown>
                            <Nav {...props} />
                        </Hidden>
                        <Hidden mdUp>
                            <IconButton onClick={handleClick} color="inherit" aria-label="open drawer">
                                {
                                    open ?
                                        <Close />
                                        :
                                        <img style={{ height: 30 }} src={require(`../../images/bars-solid.svg`)} />
                                }

                            </IconButton>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
            <Hidden mdUp>
                <NavMobile open={open} {...props} />
            </Hidden>
        </>
    )
}
