import React, { Component } from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import styled from "styled-components";

const IFrame = styled.div`
       iframe {
            width: 100%;
            height: 216px;
            border: none;
       }
       @media (max-width: 767px) { 
        iframe {
            height: 300px;
        }
       }
       @media (max-width: 600px) {  
        iframe {
            height: 368px;
        }
       }
`

class ComponentIFrame extends Component {
    constructor(props) {
        super(props)
        this.props = props;
    }
    componentDidMount() {
    }
    render() {
        return (
            <>
               <IFrame>
                    <iframe src={documentToPlainTextString(this.props.url.json)} title="dummy"></iframe>
              </IFrame>
            </>
        );
    }
}
export default ComponentIFrame