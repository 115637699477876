import React from "react"
import ComponentLink from "../../components/ComponentLink"
import { returnPath } from "../../../helper"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    root: {
       "& span": {
            font: "16px Gilroy",
            fontWeight: 500,
            color: "#02335b",
            '&:hover': {
                color: "#217ebe"
            },
            [theme.breakpoints.down("sm")]: {
                color: (props) => Boolean(props.indent) ? "#02335b" : "#ffffff",
            },
            [theme.breakpoints.up("md")]: {
                fontSize: props => props.indent ? "15px" : "16px"

            }
       }
    }
}))

export default ({ indent, ...props }) => {
    const classes = useStyles({ indent })
    return (
        <ComponentLink
            styleOption={"None"}
            actionType="Open Page"
            targetContent={returnPath(props)}
            linkText={props.navTitle}
            className={classes.root}
        />
    )
}