import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import 'plyr/dist/plyr.css';
import React, { Component } from 'react';
import "./ComponentVideo.scss";
import { makeStyles } from "@material-ui/core/styles"
import helper from "../../helper"

const useStyles = makeStyles((theme) => ({
    root: {
        "& .plyr--video.plyr--paused.plyr--stopped": {
            overflow: "unset",
            marginBottom: "30px"
        },
        "& .plyr--paused:not(.plyr--stopped) > button": {
            display: "none"
        }
    }
}))

const Video = (props) => {
    const classes = useStyles()

    var provider = "youtube"
    if (props.videoUrl.includes("vimeo")) {
        provider = "vimeo"
    }
    else if (props.videoUrl.includes("youtube")) {
        provider = "youtube"
    }
    return (
        <div className={classes.root}>
            <div
                id={helper.formatForId(props.entryTitle)}
                poster={props.placeholderImage ? props.placeholderImage.file.url : ""}
                data-plyr-provider={provider}
                data-plyr-embed-id={props.videoUrl}
            />
        </div>
    )
}


class ContentfulComponentVideo extends Component {
    constructor(props) {
        super(props)
        this.props = props;
        // console.log(this.props)
    }
    componentDidMount() {
        const Plyr = require("plyr");
        var _self = this
        const options = {
            toggleControls: false,
            hideControls: true,
            captions: { active: true },
        };
        var player = new Plyr(`#${helper.formatForId(this.props.entryTitle)}`, options);

        player.on('ready', event => {
            const instance = event.detail.plyr;
        });

        player.on('play', event => {
            const instance = event.detail.plyr;
        });

        player.on('pause', event => {
            const instance = event.detail.plyr;
        });

        player.on('playing', event => {
            const instance = event.detail.plyr;
        });
    }
    handlePause(event) {
    }
    handlePlay(event) {
    }
    handleSeeking(event) {
    }
    handleEnded(event) {
    }
    render() {


        return (
            <Video {...this.props} />
        );
    }
}




export default ContentfulComponentVideo