import React from "react"
import Grid from "@material-ui/core/Grid"
import Map from "./ComponentMap"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { height } from "@material-ui/system";
import styled from 'styled-components';


const ContactUsMapTab = styled(Grid)`
      .location {
          display:inline;
          vertical-align: top;
      }
      .address {
        padding-left: 15px;
        display: inline-block;
      }
`
const ClinicLocation = ({ address, ...props }) => {
    const options = {
        renderText: text => {
            return text.split('\n').reduce((children, textSegment, index) => {
                return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
        },
    };
    return (
        <ContactUsMapTab container>
            <Grid item xs={12} sm={5}>
                <div className="location">
                    <img style={{ height: "50px" }} src="https://images.ctfassets.net/bk594zop7zzc/7jDV6gxLOtNlgcA7EXqzZs/bc88e84dffadccf0adeb048ef0611a0d/maps-and-flags.svg"></img>
                </div>
                <div className="address">
                    {documentToReactComponents(address.json, options)}
                </div>
            </Grid>
            <Grid item xs={12} sm={7}>
                <Map {...props} />
            </Grid>
        </ContactUsMapTab>
    )
}

export default ClinicLocation