import React from 'react'
import Grid from "@material-ui/core/Grid"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from "@material-ui/core/styles"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import styled from "styled-components"

const useStyles = makeStyles({
    container: {
        borderRadius: "10px"
    },
    head: {
        backgroundColor: "#02335b",
    },
    headCell: {
        color: (props) => props.color === "white" ? "#ffffff" : "inherit",
        borderRight: (props) => props.border ? "1px solid rgba(224, 224, 224, 1)" : "0",
        width: "50%"
    }
})

const Cell = (props) => {
    const styles = useStyles(props)
    const { color, border, ...other } = props
    return <TableCell classes={{ root: styles.headCell }} {...other} />
}

const Wrapper = styled(Grid)`
    > div:last-child {
        .styledContainer {
            margin-bottom: 0
        }
        > div:not(.content) {
            padding-bottom: 0
        }
    }
    max-width: 1060px;
`

export default (props) => {
    const [head, ...tiles] = props.tiles
    const styles = useStyles()
    return (
        <Wrapper container>
            <TableContainer classes={{ root: styles.container }} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead classes={{ root: styles.head }} >
                        <TableRow>
                            <Cell color="white">{head.entryTitle}</Cell>
                            <Cell color="white">{documentToPlainTextString(head.content.json)}</Cell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tiles.map((tile, i) => {
                                return (
                                    <TableRow key={i}>
                                        <Cell border={true}>{tile.entryTitle}</Cell>
                                        <Cell>{documentToPlainTextString(tile.content.json)}</Cell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Wrapper>
    )
}