import React from "react"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ children, to, "aria-label": ariaLabel, target, ...other }) => {
    const internal = /^\/(?!\/)/.test(to)
    if (internal) {
        return (
            <GatsbyLink {...other} aria-label={ariaLabel} to={to}>{children}</GatsbyLink>
        )
    }
    return (
        <a href={to} target={target ? target : "_blank"} aria-label={ariaLabel + " (opens in a new tab)"} {...other}>{children}</a>
    )
}
export default Link
