import React from "react"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core/styles"
import { ComponentLink } from "../../components";

const useStyles = makeStyles({
    root: {
        "&:hover": {
            backgroundColor: "unset"
        },
        "& img": {
            width: "250px"
        }
    }
})

export default (props) => {
    const classes = useStyles()
    
    return (
        <IconButton disableRipple disableFocusRipple disableTouchRipple className={classes.root}>
            <ComponentLink
                styleOption={"Image"}
                actionType="Open Page"
                targetContent={"/"}
                buttonWidth="Auto"
                className="logo"
                image={{
                    imageDesktopView: props.headerLogo,
                    imageTabletView: props.headerLogo,
                    imageMobileView: props.headerLogo,
                    entryTitle: `${props.siteName} Logo`
                }}
            />
        </IconButton>
    )
}