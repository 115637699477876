import React from "react";
import { Formik, Form } from "formik";
import { Button, Grid } from '@material-ui/core';
import * as Yup from "yup";
import { TextField, Textarea } from "./fieldTypes";
import { ComponentImage } from "../components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";

const GetInTouch = styled.div`
    background-color: white;
    border-radius: 10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    h2 {
        color: #217ebe;
    }
    p {
        color: #02335b;
    } 
    .formHeader{ 
        margin: 45px;
    }
    .MuiFormHelperText-root{
     color: red;   
    }
    /* ipad Pro */
    @media (max-width: 1024px) { 
        margin: 15px;
    }
    /* ipad */
    @media (max-width: 768px) {
    
    }
    /* iphone */
    @media (max-width: 767px) {  
        .formHeader{ 
            margin: 20px 10px;
        }
        margin: 15px;
    }
`

const ImageContainer = styled(Grid)`
    @media (max-width: 600px) {  
        border: 2px solid white;
        border-radius: 10px 10px 0 0;
        img {
            border-top-right-radius: 10px;
            border-bottom-left-radius: unset;
        }
        
    }
`

const validationSchema = Yup.object().shape({
    name: Yup
        .string()
        .required('Your name is required'),
    email: Yup
        .string()
        .email("Please enter a valid email")
        .required("Email is required."),
    phone: Yup
        .string()
        .required("Phone number is required."),
    message: Yup
        .string()
        .required("Message is required.")

})

const FormWrapper = styled(Form)`
    margin: 50px 35px;
    @media(max-width: 600px){
        margin: 20px 15px;  
    }
`

const FieldGroup = styled(Grid)`
    > div:not(:last-child) {
        padding-bottom: 8px
    }
`
export default (props) => {
    let { site: { siteMetadata } } = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                formSubmitUrl
            }
            }
        }
    `)
    const [state, setState] = React.useState(0)
    const [error, setError] = React.useState(false)
    return (
        <GetInTouch>
            <Grid container>
                <ImageContainer item xs={12} sm={5} md={5}>
                    <ComponentImage {...props.image} link={true} />
                </ImageContainer>
                <Grid item xs={12} sm={7} md={7} className="text-center">
                    {
                        state === 1 ? (
                            <div className="formHeader">
                                <h2 className="text-light-blue">Thank You</h2>
                                <p>Thank you for your enquiry, we will get back to you shortly.</p>
                            </div>
                        ) : (
                                <>
                                    <div className="formHeader">
                                        <h2 className="text-light-blue">{props.heading}</h2>
                                        {documentToReactComponents(props.description.json)}
                                    </div>

                                    <Formik
                                        initialValues={{
                                            name: "",
                                            email: "",
                                            phone: "",
                                            message: "",
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={(values, props) => {
                                            props.setSubmitting(true)
                                            axios.post(siteMetadata.formSubmitUrl, values)
                                                .then(res => setState(1))
                                                .then(res => setError(false))
                                                .catch(err => setError(true))
                                                .then(res => props.setSubmitting(false))
                                        }}
                                    >
                                        {
                                            (props => (
                                                <FormWrapper>
                                                    <FieldGroup container>
                                                        <Grid style={{ textAlign: "left" }} item xs={12} sm={12} md={12}>
                                                            <p>* Required</p>
                                                            {error ? <p style={{ color: "#f44336" }}>There was an error submitting the form, please try again later.</p> : ''}
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <TextField name="name" placeholder="Name *" variant="outlined" type="text" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <TextField name="email" placeholder="Email address *" variant="outlined" type="email" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <TextField name="phone" placeholder="Phone number *" variant="outlined" type="text" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Textarea rows="4" placeholder="Message *" variant="outlined" name="message" />
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12}>
                                                            <Button disabled={props.isSubmitting} variant="contained" color="primary" className="btn-submit" type="submit">Send</Button>
                                                        </Grid>
                                                    </FieldGroup>
                                                </FormWrapper>
                                            ))
                                        }
                                    </Formik>
                                </>
                            )
                    }

                </Grid>
            </Grid>
        </GetInTouch>
    )
}