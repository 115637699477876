import React from "react"
import Hidden from "@material-ui/core/Hidden"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import AnchorLink from "react-anchor-link-smooth-scroll"
import { toCamelCase } from "../../helper"
import Link from "./Link"
import helper from "../../helper"
import * as Components from "../components"
import Image from "./ComponentImage"

const Content = ({ linkText, image, imageOnHover, hover, style }) => {
    return (
        <>
            {image ? (hover && imageOnHover ? <Image style={style} link={true} {...imageOnHover} /> : <Image style={style} link={true} {...image} />) : ""}
            <span>{linkText ? linkText : ""}</span>
        </>
    )
}
const OpenPage = ({ style, targetContent, className, ariaLabel, ...other }) => {
    var buttonClass = other.styleOption !== "None" ? `btn${other.styleOption}` : ""
    const [hover, setHover] = React.useState(false)
    return (
        <Link onMouseOut={() => setHover(false)} onMouseOver={() => setHover(true)} to={url(targetContent)} aria-label={ariaLabel} className={`${buttonClass} ${className}`}>
            <Content hover={hover} style={style} {...other} />
        </Link>
    )
}

const OpenInternalPage = ({ style, targetContent, className, ariaLabel, ...other }) => {
    var buttonClass = other.styleOption !== "None" ? `btn${other.styleOption}` : ""
    const [hover, setHover] = React.useState(false)
    // return (
    //     <Link onMouseOut={() => setHover(false)} onMouseOver={() => setHover(true)} to={url(targetContent)} aria-label={ariaLabel} className={`${buttonClass} ${className}`}>
    //         <Content hover={hover} style={style} {...other} />
    //     </Link>
    // )
    return <></>
}

const OpenExternalPage = ({ style, targetContent, className, ariaLabel, ...other }) => {
    
    var buttonClass = other.styleOption !== "None" ? `btn${other.styleOption}` : ""
    const [hover, setHover] = React.useState(false)
    return (
        <Link onMouseOut={() => setHover(false)} onMouseOver={() => setHover(true)} to={url(targetContent)} aria-label={ariaLabel} className={`${buttonClass} ${className}`}>
            <Content hover={hover} style={style} {...other} />
        </Link>
    )
}

const ScrollToAnchor = ({ targetContent, className, ...other }) => {
    const [hover, setHover] = React.useState(false)
    const offset = () => {
        if (typeof document !== undefined) {
            var nav = document.getElementById("nav")
            var navHeight = window.scrollY ? nav.getBoundingClientRect().height : nav.getBoundingClientRect().height * 2
            var tabSectionHeight = 0
            var tabs = document.querySelector(".tab-section")
            if(document.querySelector(".tab-section")) {
                tabSectionHeight = tabs.className.includes("fixed") ? tabs.getBoundingClientRect().height : tabs.getBoundingClientRect().height * 2
            }
            return navHeight + tabSectionHeight
        }
        return 100
    }

    var buttonClass = other.styleOption !== "None" ? `btn${other.styleOption}` : ""
    return (
        <AnchorLink
            onMouseOut={() => setHover(false)}
            onMouseOver={() => setHover(true)}
            href={url(targetContent).replace("/", "")}
            offset={offset}
        >
            <Content hover={hover} {...other} />
        </AnchorLink>
    )
}

const OpenPopup = ({ targetContent, className, ...other }) => {
    var buttonClass = other.styleOption !== "None" ? `btn${other.styleOption}` : ""
    const [hover, setHover] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    const options = {
        renderNode: {
            "embedded-entry-block": (node) => {
                if (node.data.target.fields) {
                    let fields = helper.removeLocale(node.data.target)
                    let Component = Components[fields.__typename.replace("Template", "Component")]
                    return <Component {...fields} open={open} handleClick={handleClick} />
                }
                return <></>
            }
        },
    };
    return (
        <>
            <button onMouseOut={() => setHover(false)} onMouseOver={() => setHover(true)} className={`${buttonClass} ${className}`} onClick={handleClick}>
                <Content hover={hover} {...other} />
            </button>
            {documentToReactComponents(targetContent.json, options)}
        </>
    )
}

const Download = ({ targetContent, className, ...other }) => {
    var buttonClass = other.styleOption !== "None" ? `btn${other.styleOption}` : ""
    var to = url(targetContent)
    const [hover, setHover] = React.useState(false)
    return (
        <>
            <Hidden mdUp>
                <Link onMouseOut={() => setHover(false)} onMouseOver={() => setHover(true)} download={true} to={to} aria-label={``} target="_self" className={`${buttonClass} ${className}`}>
                    <Content hover={hover} {...other} />
                </Link>
            </Hidden>
            <Hidden smDown>
                <Link onMouseOut={() => setHover(false)} onMouseOver={() => setHover(true)} to={to} aria-label={``} target="_self" className={`${buttonClass} ${className}`}>
                    <Content hover={hover} {...other} />
                </Link>
            </Hidden>
        </>
    )
}
function url(targetContent) {
    if (typeof targetContent === "object") {
        return documentToPlainTextString(targetContent.json ? targetContent.json : targetContent)
    }
    return targetContent
}
const actionTypes = {
    OpenPage,
    ScrollToAnchor,
    OpenPopup,
    Download,
    OpenInternalPage
}
const ContentfulTemplateLink = ({ style, actionType, textToTheRight, buttonWidth, ...other }) => {
    if (!!other.styleOption) {
        const ActionTypeDesktop = actionTypes[toCamelCase(actionType)]
        const classes = [
            textToTheRight ? "linkInfo" : "",
            buttonWidth ? `width${buttonWidth}` : "",
        ]
        return (
            <ActionTypeDesktop className={classes.join(" ")} style={style} {...other} />
        )
    }
    return <></>
}
export default ContentfulTemplateLink