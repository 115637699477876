import React from 'react';
import GoogleMapReact from 'google-map-react';
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components";

const MapLabel = styled.div`
  position: absolute;
  border: 1px solid #014999;
  background: white;
  width: max-content;
  padding: 5px 10px;
  border-radius: 5px;
  top: -5px;
  left: -50px;
`;
const MapPin = styled.img`
  height: 80px;
  width: 40px;
`;

const ContentfulTemplateMap = ({ entryTitle, location, zoomLevel }) => {

  const { site: {siteMetadata: {googleMapJsApiKey}}} = useStaticQuery(graphql`
  query { site { siteMetadata { googleMapJsApiKey } } } `)
  
  return (
    <div style={{ height: "425px", width: "100%", overflow: 'hidden', borderRadius: "10px"  }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapJsApiKey }}
        defaultCenter={{
          lat: location.lat,
          lng: location.lon,
        }}
        defaultZoom={zoomLevel}
      >
        <div className="map-pointer">
          <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${location.lat},${location.lon}`}>
            <MapLabel>{entryTitle}</MapLabel>
            <MapPin src="https://images.ctfassets.net/bk594zop7zzc/7jDV6gxLOtNlgcA7EXqzZs/bc88e84dffadccf0adeb048ef0611a0d/maps-and-flags.svg" />
          </a>
        </div>
      </GoogleMapReact>
    </div>
  );
}


export default ContentfulTemplateMap