import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import styled from 'styled-components';
import helper from "../../helper"
import * as Components from "./index";
import { returnTypename } from "helper"

const Content = styled.div`
    overflow-wrap: break-word;
    word-break: break-word;
    border-radius: 15px;
    ${props => props.isImage ?
        `padding-${props.imagePosition.toLowerCase()}: 40px`
        : !props.imagePosition ?
        props.columns !== undefined ?
            props.columns.md === 12 ?
                "" :
                (props.gridprops.isLastChild && props.gridprops.isFirstChild) ?
                    "" :
                    props.gridprops.isLastChild ?
                        `padding-left: 10px` :
                        props.gridprops.isFirstChild ?
                            `padding-right: 10px` :
                            "padding: 0 5px"
            : "" : "" 
        };
    ul {
        list-style: none;
        padding-left: 0;
    }
    ul li {
        position: relative;
        padding-left: 20px;
    }
    ul li p {
        margin-left: 5px;
    }
    ul li:before {
        content: '';
        width: 15px;
        height: 15px;
        position: absolute;
        background-image: url('https://images.ctfassets.net/bk594zop7zzc/5sXZnqyma6x0xCsHcP3aup/1b89bb2e4df48622a013aa657e0b9216/circle.svg');
        background-size: cover;
        background-position: center;
        left: 0;
        top: 13px;
        transform: translateY(-50%);
    }
    /* ipad Pro */
    @media (max-width: 1024px) { 
        padding: ${({ gridprops }) => gridprops.isLastChild ? "20px 20px 0" : (gridprops.isFirstChild ? "20px" : "20px")};
    }
    /* ipad */
    @media (max-width: 768px) {
        padding: ${({ gridprops }) => gridprops.isLastChild ? "20px 20px 0" : (gridprops.isFirstChild ? "20px" : "20px")};  
    }
    /* iphone */
    @media (max-width: 767px) { 
        border-radius: 0;
        padding: ${({ gridprops }) => gridprops.isLastChild ? "15px" : (gridprops.isFirstChild ? "20px" : "20px")};
        
        > ul {
            margin-top: 0;
        }
        
        a {
            text-align: center;
        }
    }
`;

export default (props) => {
    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                return <div className="text-center" style={{ paddingBottom: "20px" }}>
                    <img src={node.data.target.fields.file.en.url} />
                </div>
            },
            [BLOCKS.EMBEDDED_ENTRY]: (node) => {
                if (node.data.target.fields) {
                    let fields = helper.removeLocale(node.data.target)
                    let Component = Components[returnTypename(fields.__typename)]
                    return <Component onlyContent={props.onlyContent} {...fields} />
                }
                return (
                    <a>{node.data.target.fields.entryTitle.en}</a>
                )
            }
        }
    }
    var backgroundColor = props.backgroundColor ? `bg-${props.backgroundColor.toLowerCase().replace(/\s/g, "-")}` : ""
    var classes = `${props.className ? props.className : ""} ${backgroundColor} content`.trim()
    if (props.onlyContent) {
        return documentToReactComponents(props.content.json ? props.content.json : props.content, options)
    }
    return (
        <>{props.content && <Content gridprops={props.gridProps || { isLastChild: "", isFirstChild: "" }} {...props} className={classes}>{documentToReactComponents(props.content.json ? props.content.json : props.content, options)}</Content>}</>
    )
}
