import React from "react"
import { Grid } from "@material-ui/core";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import styled from "styled-components";
import { Hidden } from "@material-ui/core"

const RecommendationImage = styled.div`
    img {
        border-radius: 50%;
    }
    img.myHealthSpecialist {
        border: 3px solid #33669a;
    }
    img.Doctify {
        border: 3px solid #39c6b4;
    }
    img.TopDoctors {
        border: 3px solid #002d4b;
    }
    /* ipad Pro */
    @media (max-width: 1024px) { 
        
    }
    /* ipad */
    @media (max-width: 768px) {
        padding: 0 0 20px;
    }
    /* iphone */
    @media (max-width: 767px) {
        padding: 0 0 20px;
        img {
            height: 125px;
            margin: 0 auto;
        }
    }
`
const RecommendationNote = styled.div`
    border-radius: 10px;
    padding: 50px 30px ;
    @media (max-width: 768px) { 
        padding: 25px;
        margin: 15px;
        // height:380px;
        .MuiGrid-root.text-center.MuiGrid-item.MuiGrid-grid-md-3 {
            margin: 0 auto;
        }
    }
`

const Arrow = styled.div`
        position: relative;
        img {
            width: 60px;
            object-fit: cover;
            position:absolute;
            &.row {
                left:-85px;
            }
            &.row-reverse {
                right:-85px;
                transform: rotate(180deg);
            }
        }
        @media (max-width: 768px) {  
            img {
                width: 30px;
                top: -55px;
                &.row {
                    left: 45%;
                    transform: rotate(90deg);
                }
                &.row-reverse {
                    right:unset;
                    left: 45%;
                    transform: rotate(90deg);
                }
            } 
        }

`

export default ({avatarOnLeft, ...props}) => {
    var direction = "row"
        if(avatarOnLeft) {
            direction = "row-reverse"
        }
    return (
        <Grid container direction={direction} className="recommendation">
            <Grid item xs={12} sm={12} md={4} className="text-center">
                <RecommendationImage>
                    <img src={ props.avatar.file.url } className={props.recommendationPlatform} alt="avatar" />
                </RecommendationImage>
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
                <RecommendationNote className={props.parentBackgroundcolor === "bg-white" ? "bg-light-grey" : "bg-white"}>
                    <Arrow><img src={ props.parentBackgroundcolor === "bg-white" ? require("../images/left-speech-arrow-grey.svg"): require("../images/left-speech-arrow.svg")}  className={direction}  /></Arrow>
                    <h4>{props.recommendedBy}</h4>
                    <Hidden xsDown>
                        {documentToReactComponents(props.testimonial.json)}
                    </Hidden>
                    <Hidden smUp>
                        {props.testimonialSummary ? props.testimonialSummary : documentToReactComponents(props.testimonial.json)}
                        {props.testimonialSummary ? <div className="read-more"><a href="/testimonials">Read more..</a></div> : <></>}
                    </Hidden>
                </RecommendationNote>
            </Grid>
        </Grid>
    )
}