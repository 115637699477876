import React from 'react'
import { useField } from 'formik';
import MTextField from "@material-ui/core/TextField"

const TextField = ({ placeholder, variant, ...props }) => {
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ""
    return (
        <MTextField error={!!errorText} placeholder={placeholder} {...field} variant={variant} helperText={errorText} />
    )
}
export default TextField

