import React from "react"
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import Page from "./Link"
import Link from "../../components/Link"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles"
import { returnTypename } from "helper";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { returnPath } from "../../../helper"

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: "unset",
        padding: "0",
        minWidth: "unset",
        font: "16px Gilroy",
        alignItems: "flex-end",
        color: "#02335b",
        verticalAlign: "unset",
        '&:hover': {
            backgroundColor: "unset",
            color: "#217ebe"
        },
        '& .MuiButton-endIcon': {
            margin: 0
        },
        '& .MuiButton-label': {
            font: "16px Gilroy",
            fontWeight: 500
        },
        [theme.breakpoints.down("sm")]: {
            color: (props) => Boolean(props.indent) ? "#02335b" : "#ffffff",
            '&:hover': {
                color: (props) => Boolean(props.indent) ? "#02335b" : "#ffffff",
            },
            width: "100%",
            '& .MuiButton-label': {
                justifyContent: "space-between",
                display: "flex"
            }
        }
    },
    listItem: {
        '&:before': {
            display: "none"
        },
        [theme.breakpoints.down("sm")]: {
            font: "14px Gilroy",
            backgroundColor: (props) => {
                if (props.indent === 1) {
                    return "#f1f1f1";
                }
                else if (props.indent === 2) {
                    return "#ffffff";
                }
                return "#02335b";
            },
            borderTop: (props) => Boolean(props.indent) ? "1px solid #02335b" : "1px solid #ffffff",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: (props) => {
                return props.indent ? `${16 + (12 * props.indent)}px` : "16px"
            }
        }
    },
    subNav: {
        [theme.breakpoints.up("md")]: {
            paddingLeft: "16px"
        }
    }
}))

export const DropdownItem = ({ indent, ...props }) => {
    // console.log(indent)
    const classes = useStyles({ indent })
    return (
        <Link to={returnPath(props)}>
            <ListItem classes={{ root: classes.listItem }} >
                <Page indent={indent} {...props} />
            </ListItem>
        </Link>
    )
}

export const SubNavigation = ({ entryTitle, items, desktop, indent }) => {
    const [listOpen, setListOpen] = React.useState(false);
    const [Icon, setIcon] = React.useState(desktop ? ArrowDropDownIcon : ExpandMore);
    const classes = useStyles({ indent })
    const handleClick = (open) => {
        setIcon(listOpen ? desktop ? ArrowDropDownIcon : ExpandMore : desktop ? ArrowDropUpIcon : ExpandLess)
        setListOpen(!listOpen);
    };

    const clickAway = () => {
        setIcon(listOpen ? desktop ? ArrowDropDownIcon : ExpandMore : desktop ? ArrowDropUpIcon : ExpandLess)
        setListOpen(false);
    };

    // console.log(indent)

    return (
        <>
            <ListItem classes={{ root: classes.listItem }}>
                <Button onClick={handleClick} endIcon={<Icon />} classes={{ root: classes.root }} disableTouchRipple disableFocusRipple disableRipple>{entryTitle}</Button>
            </ListItem>

            <Collapse in={listOpen} timeout="auto" unmountOnExit>
                <ClickAwayListener onClickAway={clickAway}>
                    <List disablePadding classes={{ root: classes.subNav }}>
                        {
                            items.map((item, i) => {
                                if (returnTypename(item.__typename) === "NavigationDropdown") {
                                    return <SubNavigation indent={indent ? indent + 1 : 1} key={i} {...item} />

                                }
                                return (
                                    <DropdownItem indent={indent ? indent + 1 : 1} key={i} {...item} />
                                )
                            })
                        }
                    </List>
                </ClickAwayListener>
            </Collapse>
        </>
    )

}


const NavigationDropdown = ({ entryTitle, items }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [Icon, setIcon] = React.useState(ArrowDropDownIcon);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setIcon(ArrowDropUpIcon)
    }
    const handleClose = () => {
        setAnchorEl(null);
        setIcon(ArrowDropDownIcon)
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const classes = useStyles()

    return (
        <>
            <Button onClick={handleClick} endIcon={<Icon />} classes={{ root: classes.root }} disableTouchRipple disableFocusRipple disableRipple>{entryTitle}</Button>
            <Popover
                id={id}
                disableScrollLock
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <List>
                    {
                        items.map((item, i) => {
                            if (returnTypename(item.__typename) === "NavigationDropdown") {
                                return <SubNavigation indent={true} desktop={true} key={i} {...item} />

                            }
                            return (
                                <DropdownItem key={i} {...item} />
                            )
                        })
                    }
                </List>
            </Popover>
        </>
    )
}

export default NavigationDropdown