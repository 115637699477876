import React from "react"
import Navbar from "./Navbar"
import { ComponentLink } from "../components"
import { Grid } from "@material-ui/core"
import styled from "styled-components";

const HeaderBanner = styled.div`
    padding 12px 0;
    /* ipad Pro */
    @media (max-width: 1024px) { 
        padding 15px;
    }
    /* ipad */
    @media (max-width: 768px) {
        padding 15px;
    }
    /* iphone */
    @media (max-width: 767px) {
        display:none;
    }
`
const SocialMediaAccounts = styled.div`
    a img {
        width: auto;
        height: 15px;
        padding-right: 20px;
    }
    a:hover img {
        width: auto;
        height: 15px;
        padding-right: 20px;
    }
`
const BasicInfo = styled.div`
    a {
        margin-right: 20px;
        color: #ffffff;
        img {
            width: auto;
            height: 15px;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    a:hover{
        span {
            border-bottom: 2px solid #ffffff
        }
    }
    a:last-child {
        margin-right: 0;
    }
    
`
export default (props) => {
    return (
        <header>
            <HeaderBanner className="bg-dark-blue">
                <Grid container justify="space-between">
                    <SocialMediaAccounts>
                        {
                            props.socialMediaAccounts.map((item, i) => (
                                <ComponentLink {...item} key={i} />
                            ))
                        }
                    </SocialMediaAccounts>

                    <BasicInfo>
                        <ComponentLink {...props.email} />
                        <ComponentLink {...props.phoneNumber} />
                    </BasicInfo>
                </Grid>
            </HeaderBanner>
            <Navbar {...props} />
        </header>
    )
}
