import React from "react"
import { Grid } from "@material-ui/core";
import * as Components from "../components"
import { returnTypename, returnColumns } from "helper"
import Box from "@material-ui/core/Box";
import styled from "styled-components"

const Wrapper = styled(Grid)`
    > div:last-child {
        .styledContainer {
            margin-bottom: 0
        }
        > div:not(.content) {
            padding-bottom: 0
        }
    }
    max-width: 1060px;
    @media (max-width: 600px) { 
        > div:last-child {
            > div:not(.content) {
                padding-bottom: 15px;
            }
        }
    }
`

export default (props) => {
    var columns = returnColumns(props, { noColumnsDesktopView: 1, noColumnsMobileView: 1, noColumnsTabletView: 1 })
    var gridClass = props.gridClass ? props.gridClass : "secContainer"

    return (
        <Wrapper container className={gridClass}>
            {
                props.tiles && props.tiles.map((tile, i) => {
                    var componentType = returnTypename(tile.__typename);
                    var Component = Components[componentType]

                    if (componentType === "ComponentVideo") {
                        return (
                            <Grid item key={i} {...columns}>
                                <Box alignItems="center" >
                                    <Component {...tile} />
                                </Box>
                            </Grid>
                        )
                    }
                    return (
                        <Grid item key={i} {...columns}>
                            <Component parentBackgroundcolor={props.parentBackgroundcolor} columns={columns} gridProps={{ isLastChild: props.tiles.length === (i + 1), isFirstChild: !i }}  {...tile} />
                        </Grid>
                    )
                })
            }
        </Wrapper>
    )
}