const moment = require("moment")
module.exports = {
    formatDate(date, originalFormat, newFormat) {
        return moment(date, originalFormat).format(newFormat)
    },
    formatForId(text) {
        return text.replace(/[^a-zA-Z0-9 ]/g, "").replace(/\s/g, "-").toLowerCase()
    },
    getImageInfo(image) {
        let img = {}
        try {
            img = {
                imgUrl: image ? image.file.url : "",
                imgTitle: image ? (image.description ? image.description : image.title) : ""
            }
        }
        catch (e) {
            let tempImg = module.exports.removeLocale(image)
            img = {
                imgUrl: tempImg ? tempImg.file.url : "",
                imgTitle: tempImg ? (tempImg.description ? tempImg.description : tempImg.title) : ""
            }
        }
        return img
    },
    gridClassesAvailable(gridClass) {
        if (gridClass) {
            return gridClass;
        }
        return "MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-12";
    },
    returnPath(props) {
        var url = ""
        if (props.__typename.replace("Contentful", "") === "Page") {
            var slug = module.exports.formatForId(props.pageTitle)
            var pageType = module.exports.formatForId(props.pageType)
            if (pageType === "home") {
                url = "/"
            }
            else if (pageType === "main" || !pageType) {
                url = "/" + slug
            }
            else {
                url = `/${pageType.toLowerCase()}/${slug}`  
            }
        }
        else {
            url = props.linkToContent
        }
        return url
    },
    returnTypename(typeName) {
        return typeName.replace("Contentful", "")
    },
    returnColumns(cols, def) {
        var mapping = {
            noColumnsDesktopView: "md",
            noColumnsTabletView: "sm",
            noColumnsMobileView: "xs"
        }
        var obj = {};
        for (var col in mapping) {
            obj[mapping[col]] = cols[col] ? 12 / parseInt(cols[col]) : parseInt(def[col])
        }
        return obj
    },
    removeLocale(data) {
        var obj = {}
        if (data.fields) {
            for (const [key, val] of Object.entries(data.fields)) {
                let value = val['en'] ? val['en'] : val
                if (Array.isArray(value)) {
                    obj[key] = []
                    value.forEach(val => {
                        obj[key].push(this.removeLocale(val))
                    })
                }
                else if (typeof value === 'object') {
                    obj[key] = this.removeLocale(value)
                }
                else {
                    obj[key] = value
                }
            }
        }
        else {
            obj = data
        }
        if (data.sys && data.sys.type === "Entry") {
            let contentType = data.sys.contentType.sys.id
            obj.__typename = `Contentful${contentType.charAt(0).toUpperCase()}${contentType.slice(1)}`.replace("Template", "Component")
        }
        return obj
    },
    toCamelCase(input) {
        let words = input.match(/[A-Z\xC0-\xD6\xD8-\xDE]?[a-z\xDF-\xF6\xF8-\xFF]+|[A-Z\xC0-\xD6\xD8-\xDE]+(?![a-z\xDF-\xF6\xF8-\xFF])|\d+/g);
        let result = "";
        for (let i = 0, len = words.length; i < len; i++) {
            let tempStr = words[i].toLowerCase();
            result += tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
        }
        return result;
    }
}