import React from "react"
import Slider from "react-slick";
import * as Components from "../components";
import { Grid } from "@material-ui/core";
import { returnTypename, returnColumns } from "helper"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carousel.scss";
import styled from "styled-components";

const StyledSlider = styled(Slider)`
    padding-bottom: 10px;
    @media (max-width: 600px) { 
        padding-bottom: 0;
    }
`

function CarouselArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div role="button" aria-label="arrow" onKeyDown={onClick} onClick={onClick}><img className={className} style={{ ...style }} src={require(`../images/${props.type}_arrow.svg`)} alt={"icon of " + props.type + " arrow"} /></div>
    );
}
const Carousel = (props) => {
    // console.log("parentBackgroundcolor",props.parentBackgroundcolor)
    var multiImgSettings = {
        dots: true,
        fade: false,
        speed: 1000,
        pauseOnFocus: true,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: props.noColumnsDesktopView,
        slidesToScroll: props.noColumnsDesktopView,
        nextArrow: <CarouselArrow type="next" />,
        prevArrow: <CarouselArrow type="prev" />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: props.noColumnsTabletView,
                    slidesToScroll: props.noColumnsTabletView,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: props.noColumnsMobileView,
                    slidesToScroll: props.noColumnsMobileView,
                    infinite: true,
                    dots: true
                }
            }
        ]
    };
    return (
        <StyledSlider {...multiImgSettings}>
            {
                props.tiles.map((tile, i) => {
                    let Component = Components[returnTypename(tile.__typename)];
                    return <Component parentBackgroundcolor={props.parentBackgroundcolor}  {...tile} key={i} />
                    // return <>{i}</>
                })
            }
        </StyledSlider>
    )
}

export default ({ gridClass, ...props }) => {
    var gridClass = props.gridClass ? props.gridClass : gridClass
    return (
        <Grid container className={gridClass}>
            <Grid style={{ marginBottom: "25px" }} item xs={12} sm={12} md={12}>
                <Carousel parentBackgroundcolor={props.parentBackgroundcolor} {...props} />
            </Grid>
        </Grid>
    )

}

