import React from "react";
import { Grid, Hidden } from "@material-ui/core";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import * as Components from "../components";
import { returnTypename, getImageInfo } from "helper"
import { toCamelCase } from "../../helper";
import styled from "styled-components";

const Wrapper = styled(Grid)`
    > div:last-child {
        .styledContainer {
            margin-bottom: 0
        }
        > div:not(.content) {
            padding-bottom: 0
        }
    }
    max-width: 1060px;
`

const StyledSection = styled.section`
    &.has-bg + section {
        @media (max-width: 600px) {
            > div:first-child {
                .headingContainer {
                    padding-top: 20px;
                }
            }
        }
    }
`

const SectionContainer = styled(Grid)`
    padding-bottom: ${props => props.subHeading ? "20px" : "0"};
    /* ipad Pro */
    @media (max-width: 1024px) { 
        padding: 0 15px;
    }
    /* ipad */
    @media (max-width: 768px) {
    
    }
    /* iphone */
    @media (max-width: 767px) {
        padding: 0 15px;
        p {
            max-width: unset;
        }
    }
    
`

const H2 = styled.h2`
    margin-bottom: ${props => props.subHeading ? "10px" : "27px"};
    @media (max-width: 600px) {
        margin-bottom: ${props => props.subHeading ? "10px" : "17px"};
    }
`

const BgImage = styled.div`
    img {
        width: 100%;
        height: auto;
    }
`
const WrapperDiv = styled.div`
    background: ${props => props.page !== "Home" && props.bgImage ? "" : ""};
    min-height: ${props => props.bgImage ? "350px" : ""};
    padding: ${props => props.bgImage ? "0" : ""};
    > div:not(:last-child)${props => props.hasHeading ? ":not(:first-child)" : ""} {
        margin-bottom: ${props => !props.bgImage ? "50px" : "0"} !important;
    }
    @media (max-width: 600px) {
        padding: 0;
        min-height: unset;
        > div:not(:last-child)${props => props.hasHeading ? ":not(:first-child)" : ""} {
            margin-bottom: ${props => !props.bgImage ? "25px" : "0"} !important;
        }
    }
    @media screen and (min-width: 2560px) { 
        padding: ${props => props.page === "Home" && props.bgImage ? "160px 0" : ""};
    }
`


const Section = ({ backgroundImage,isBanner, ...props }) => {
    // console.log("isBanner",isBanner)
    var styles = {};
    var styles2 = {};
    var bgClass = "";
    var pageType = props.page.pageType;
    if (backgroundImage) {
        var { imgUrl } = getImageInfo(backgroundImage)
        if (isBanner === true) {

        } else {
            styles = {
                ...styles,
                backgroundImage: `url(${imgUrl})`,
                backgroundRepeat: `no-repeat`,
                backgroundSize: "cover",
                padding: "70px 0",
        }
        }

        if (pageType !== "Home") {
            styles2 = {
                background: `-webkit-gradient(linear,right top,left top,from(transparent),to(rgba(0,0,0,.8)))`,
                background: `linear-gradient(270deg,transparent,rgba(0,0,0,.8))`,
            }
            bgClass = "bg-image"
        }

        styles2 = {
            ...styles2,
            minHeight: "350px",
            padding: "80px 0",
        }
    }

    var backgroundColor = props.backgroundColor ? `bg-${props.backgroundColor.toLowerCase().replace(/\s/g, "-")}` : bgClass
    var anchor = (props.heading ? toCamelCase(props.heading) : "#");

    return (
        <StyledSection id={anchor} style={styles} className={`${backgroundColor} ${!!backgroundImage ? 'has-bg' : ""} ${isBanner === true ? 'has-banner' : ""}`.trim()}> 
            <WrapperDiv hasHeading={props.heading} bgImage={backgroundImage} page={pageType}>
                {isBanner === true ? 
                        <BgImage>
                            <img src={imgUrl} />
                        </BgImage>
                        : 
                        <></>
                }
                {
                    (props.heading || props.subHeading) && (
                        <Wrapper container className="secContainer">
                            <SectionContainer subHeading={!!props.subHeading} className="headingContainer" item xs={12} sm={12} md={12}>
                                {props.heading && <H2 subHeading={!!props.subHeading} className="text-center text-light-blue">{props.heading}</H2>}
                                {props.subHeading && documentToReactComponents(props.subHeading.json)}
                            </SectionContainer>
                        </Wrapper>
                    )
                }
                {
                    props.components && props.components.map((component, i) => {
                        var Component = Components[returnTypename(component.__typename)]
                        return (
                            <Component parentBackgroundcolor={backgroundColor} key={i} {...component} />
                        )
                    })
                }
            </WrapperDiv>
            
        </StyledSection>
    )
}


const HiddenMap = {
    Desktop: ["md", "lg", "xl"],
    Tablet: ["sm"],
    Mobile: ["xs"],
}

const SectionWrapper = ({ hidden, ...props }) => {
    if (hidden) {
        return (
            <>
                <Hidden only={hidden.reduce((all, curr) => [...all, ...HiddenMap[curr]], [])}>
                    <Section {...props} />
                </Hidden>
            </>
        )
    }
    return (
        <Section {...props} />
    )

}

export default SectionWrapper