import React from "react"
import * as Grids from "../grids"
import { Hidden } from "@material-ui/core"

export default ({ gridType, gridTypeMobileView, ...props }) => (
    <>
        <Hidden xsDown>
            {Grids[gridType ? gridType : "Default"](props)}
        </Hidden>
        <Hidden smUp>
            {Grids[gridTypeMobileView ? gridTypeMobileView : (gridType ? gridType : "Default")](props)}
        </Hidden>
    </>
)