import React from 'react';
import { Grid } from "@material-ui/core";
import styled from "styled-components";

const ContactUsBar = styled.div`
        .contact-info-icon {
            vertical-align: top;
            display: inline-block;
            img {
                height: 30px;
                width: 30px;
                padding-top: 10px;
            }
        }
        .contact-info {
            vertical-align: middle;
            display: inline-block;
            padding-left: 10px;
            > p {
                line-height: 26px;
                margin: 0;
            }
            p:last-child {
                font-size: 15px;
            }
        }
        p {
            font-weight: 500;
            a {
                color: #02335B;
            }
            a:hover {
                color: #217ebe;
            }
            
        }
        button {
            margin: 0;
        }
        /* ipad Pro */
        @media (max-width: 1024px) { 
        }
        /* ipad */
        @media (max-width: 768px) {
            padding: 20px 15px;
        }
        /* iphone */
        @media (max-width: 767px) {  
            padding: 15px;
            button {
                width: 100%;
            }
        }
`
const ContactUsBarTab = styled(Grid)`
        padding: 15px 50px;
`

const ComponentContactUsBar = ({ entryTitle, contactIconImage, faxIconImage, emailIconImage, emailAddressLabel, ctaButtonLabel, faxNumberLabel, contactNumberLabel }) => {
    // let { contactNumber, emailAddress, faxNumber } = config
    return (
        <>
            <ContactUsBar>
                <Grid container className="bg-light-grey" style={{ borderRadius: "10px", boxShadow: "0 3px 12px 0 rgba(0, 0, 0, 0.2)" }}>
                    <ContactUsBarTab item xs={12} sm={6} md={4}>
                        <div className="contact-info-icon">
                            <img alt={contactIconImage.description} src={contactIconImage.file.url} />
                        </div>
                        <div className="contact-info">
                            <p><a href={"tel:+442071579751"}>020 7157 9751</a></p>
                            <p>{contactNumberLabel}</p>
                        </div>
                    </ContactUsBarTab>
                    <ContactUsBarTab item xs={12} sm={6} md={4}>
                        <div className="contact-info-icon">
                            <img alt={faxIconImage.description} src={faxIconImage.file.url} />
                        </div>
                        <div className="contact-info">
                            <p><a href={"tel:+4401753860071"}>01753 860071</a></p>
                            <p>{faxNumberLabel}</p>
                        </div>
                    </ContactUsBarTab>
                    <ContactUsBarTab item xs={12} sm={6} md={4}>
                        <div className="contact-info-icon">
                            <img alt={emailIconImage.description} src={emailIconImage.file.url} />
                        </div>
                        <div className="contact-info">
                            <p><a href={"mailto:pa@sageurology.co.uk"} target="_top">pa@sageurology.co.uk</a></p>
                            <p>{emailAddressLabel}</p>
                        </div>
                    </ContactUsBarTab>
                </Grid>
            </ContactUsBar>
        </>
    );
}


export default ComponentContactUsBar