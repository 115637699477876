import React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

export default (props) => {
    let defaultTitle = "Sage Urology | Private Urologists | London";
    let title = props.entryTitle ? props.entryTitle + " | " + defaultTitle : defaultTitle;
    let discription = props.pageDescription ? documentToPlainTextString(props.pageDescription.json) : defaultTitle ;
    let image = props.pageImage ? props.pageImage.file.url : defaultTitle;
    let { site: { siteMetadata } } = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                searchEngineIndexing
            }
            }
        }
    `)

    return (
        <Helmet title={title} htmlAttributes={{ lang: 'en' }} >
            <meta charset="utf-8" />
            <meta property="og:title" content={title} />
            <meta name="description" content={discription} />
            <meta property="og:image" content={image} />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0"></meta>
            <meta name="og:email" content={props.email.linkText} />
            <meta name="og:phone_number" content={props.phoneNumber.linkText} />
            <meta name="robots" content={ siteMetadata.searchEngineIndexing }/>
        </Helmet>
    )
}