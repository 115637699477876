import React from "react"
import { ComponentLink } from "../components"
import { Grid } from "@material-ui/core"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { returnPath } from "helper";
import styled from "styled-components";
import { Link } from 'gatsby';
import { Hidden } from "@material-ui/core"

const FooterInfo = styled.div`
    padding: 50px 0;
    p{
        img {
            display: none;
        }
    }
    @media (max-width: 767px) {
        padding: 25px 15px;
        p {
            margin:0 0 17px;
        }
    }
`
const Copyright = styled.div`
    padding: 20px 0;
    @media (max-width: 767px) {
        padding: 20px 15px 60px 15px;
    }
`
const FooterLeftInfo = styled(Grid)`
    justify-content: center;
    @media (max-width: 768px) {
        // > div {
        //     padding: 25px 0;
        // }
        text-align: center;
    }
`
const FooterRightInfo = styled(Grid)`
    justify-content: center;
    @media (max-width: 768px) {
        // > div {
        //     padding-top: 25px;
        // }
        text-align: center;
    }
`

export default (props) => {
    const options = {
        renderText: text => {
            return text.split('\n').reduce((children, textSegment, index) => {
                return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
        },
    };
    return (
        <footer>
            <FooterInfo className="bg-light-blue">
                <Grid container>
                    <Grid item xs={12} sm={12} md={12} className="text-center">
                        <h2>See us in person</h2>
                        <p>We strive to provide an individualised experience to all our patients</p>
                    </Grid>

                    <Hidden xsDown>
                        <FooterLeftInfo container item xs={12} sm={3} md={3}>
                            <div>
                                <h4>Correspondence address</h4>
                                {documentToReactComponents(props.address.json, options)}
                            </div>
                        </FooterLeftInfo>
                        <FooterLeftInfo container item xs={12} sm={3} md={3}>
                            <img src={props.footerImage1.file.url} height="145px" />
                        </FooterLeftInfo>
                        <FooterLeftInfo container item xs={12} sm={3} md={3}>
                            <img src="https://images.ctfassets.net/bk594zop7zzc/6ALakFnGnr9ounmMGVnuln/f209450bf94912831a4b321b347deaa5/doctify.svg" width="120px" />
                            <img src="https://images.ctfassets.net/bk594zop7zzc/45oG4VClVqW0xXN0gTerau/b9e652926ed8a953e33d691618f326cd/top_doctoes.svg" width="220px" />
                        </FooterLeftInfo>
                        <FooterRightInfo container item xs={12} sm={3} md={3}>
                            <div>
                                <h4>Appointments</h4>
                                <p>Email: <ComponentLink {...props.email} /></p>
                                <p>Phone: <ComponentLink {...props.phoneNumber} /></p>
                                <p>Fax: {props.fax}</p>
                            </div>
                        </FooterRightInfo>
                    </Hidden>
                    <Hidden smUp>
                        <FooterRightInfo container item xs={12} sm={3} md={3}>
                            <div>
                                <h4>Appointments</h4>
                                <p>Email: <ComponentLink {...props.email} /></p>
                                <p>Phone: <ComponentLink {...props.phoneNumber} /></p>
                                <p>Fax: {props.fax}</p>
                            </div>
                        </FooterRightInfo>
                        <FooterLeftInfo container item xs={12} sm={3} md={3}>
                            <div>
                                <h4>Correspondence address</h4>
                                {documentToReactComponents(props.address.json, options)}
                            </div>
                        </FooterLeftInfo>
                        <FooterLeftInfo container item xs={12} sm={3} md={3}>
                            <img src={props.footerImage1.file.url} height="145px" />
                        </FooterLeftInfo>
                        <FooterLeftInfo container item xs={12} sm={3} md={3}>
                            <div>
                            <img style={{ padding: "15px" }} src="https://images.ctfassets.net/bk594zop7zzc/6ALakFnGnr9ounmMGVnuln/f209450bf94912831a4b321b347deaa5/doctify.svg" width="160px" />
                            </div>
                            <div>
                            <img style={{ padding: "15px 0 0 0" }} src="https://images.ctfassets.net/bk594zop7zzc/45oG4VClVqW0xXN0gTerau/b9e652926ed8a953e33d691618f326cd/top_doctoes.svg" width="230px"/>
                            </div>
                        </FooterLeftInfo>
                    </Hidden>
                </Grid>
            </FooterInfo>
            <Copyright className="bg-dark-blue text-center">
                Copyright @ 2021 Mr Marc E Laniado - All Rights Reserved.&nbsp;|&nbsp;                {
                    props.footerQuickLinks.map((props, i) => {
                        return (
                            <React.Fragment key={i}>
                                <ComponentLink key={i} styleOption={"None"} actionType="Open Page" targetContent={returnPath(props)} linkText={props.navTitle} />
                            </React.Fragment>
                        )
                    })
                }
            </Copyright>
        </footer>
    )
}
