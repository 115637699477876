import React from "react"
import { graphql } from "gatsby"
import Layout from "../site/Layout"
import { Section } from "../components"

export const data = graphql`
query ($contentful_id: String) {
	contentfulPage(contentful_id: {eq: $contentful_id}) {
	  ...page
	  sections {
		__typename
		heading
		hidden
		subHeading {
		  json
		}
		backgroundColor
		backgroundImage { ...mediaAsset }
		isBanner
		components {
		  __typename
		  ...grid
		  }
		}
	  }
	}

	fragment page on ContentfulPage { __typename entryTitle pageDescription { json } pageImage { ...mediaAsset } navTitle pageType slug }
	fragment content on ContentfulComponentContent { __typename entryTitle content { json } backgroundColor }
	fragment grid on ContentfulComponentGrid { __typename entryTitle displayEntryTitle noColumnsDesktopView noColumnsTabletView noColumnsMobileView tiles { __typename ...imageWContent ...clinicLocation ...recommendation ...content ...image ...link ...iframe ...video ...form ...contactUsBar ...map} gridType gridTypeMobileView gridClass }
	fragment imageWContent on ContentfulComponentImageWContent { __typename entryTitle content { json } imagePosition textOverlaid image { ...image } imageSize}
	fragment form on ContentfulComponentForm { __typename formType heading description { json } image { ...image } }
	fragment image on ContentfulComponentImage { __typename entryTitle imageDesktopView { ...mediaAsset } imageTabletView { ...mediaAsset } imageMobileView { ...mediaAsset } }
	fragment recommendation on ContentfulComponentRecommendation {__typename recommendedBy recommendationPlatform testimonial { json } testimonialSummary  avatar { ...mediaAsset } avatarOnLeft }
	fragment mediaAsset on ContentfulAsset { __typename title file { url } } 
	fragment link on ContentfulComponentLink { __typename linkText styleOption actionType buttonWidth targetContent { json } }
	fragment video on ContentfulComponentVideo { __typename entryTitle videoUrl placeholderImage { ...mediaAsset } description { json }  }
	fragment iframe on ContentfulComponentIFrame { __typename entryTitle url { json } }
	fragment contactUsBar on ContentfulComponentContactUsBar {
		__typename
		entryTitle
		contactIconImage {
		  ...mediaAsset
		}
		contactNumberLabel
		faxIconImage {
		  ...mediaAsset
		}
		faxNumberLabel
		emailIconImage {
		  ...mediaAsset
		}
		emailAddressLabel
	  }
	  fragment map on ContentfulComponentMap {
		entryTitle
		__typename
		latLong {
		  lat
		  lon
		}
		latLong2 {
		  lat
		  lon
		}
		latLong3 {
		  lat
		  lon
		}
		latLong4 {
		  lat
		  lon
		}
	  }

	fragment clinicLocation on ContentfulClinicLocation {
		entryTitle
		address {
			json
		}
		location {
			lat
			lon
		}
		zoomLevel
	}
`

const Page = ({ data: { contentfulPage }, pageContext: { siteConfig } }) => {
	let pageData = { ...siteConfig, ...contentfulPage };
	return (
		<Layout {...pageData}>
			{
				contentfulPage.sections.map((section, i) => {
					return (
						<Section key={i} {...section} page={pageData} />
					)
				})
			}
		</Layout>
	)
}

export default Page