import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import * as Components from "../components"
import { Grid, Hidden } from "@material-ui/core";
import { returnTypename } from "helper"
import AppBar from '@material-ui/core/AppBar';
import styled from "styled-components";
import "./Tabs.scss";

const StyledGridContainer = styled(Grid)`
    max-width: 1060px;
`

const TabContainer = styled.div`
    .MuiTabs-indicator {
        display:none;
    }
    .MuiTab-root {
        max-width: unset;
    }
    .content {
        padding: 0;
        p {
            margin-bottom: 17px;
            color: #02335b;
        }
        p:lastchild {
            margin-bottom: 0;
        }
    }
    /* ipad Pro */
    @media (max-width: 1024px) { 
     margin: 0 15px;
    }
   
    /* ipad */
    @media (max-width: 768px) {
        
        
    }
    /* iphone */
    @media (max-width: 600px) { 
        border: unset;
        display: unset;
        .tabpanel {
            padding: 25px 15px;
        }
    }
`
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`scrollable-tabpanel-${index}`} aria-labelledby={`scrollable-tab-${index}`} {...other}>
            {value === index && (children)}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-tab-${index}`,
        'aria-controls': `scrollable-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const Wrapper = (props) => {
    // if (props.isVertical) {
    //     return (
    //         <Tabs orientation="vertical" variant="scrollable" value={props.value} onChange={props.handleChange} aria-label="Vertical tabs example" className={props.classes.tabs} >
    //             {props.children}
    //         </Tabs>
    //     )
    // }
    return (
        <AppBar position="static">
            <Tabs value={props.value} onChange={props.handleChange} variant="scrollable" scrollButtons="off" indicatorColor="primary" textColor="primary" aria-label="scrollable force tabs example" >
                {props.children}
            </Tabs>
        </AppBar>
    )
}

export default function VerticalTabs(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (_, newValue) => {
        setValue(newValue);
    };
    var gridClass = props.gridClass ? props.gridClass : ""

    return (
        <StyledGridContainer container className={gridClass}>
            {
                props.displayEntryTitle ? (
                    <Grid item xs={12}>
                        <h3>{props.entryTitle}</h3>
                    </Grid>
                ) : ""
            }
            <Grid item xs={12}>
                <TabContainer>
                    {/* <Hidden xsDown>
                        <Wrapper value={value} isVertical={true} handleChange={handleChange} aria-label="Vertical tabs example" classes={classes.tabs} >
                            {props.tiles.map((tile, i) => (<Tab key={i} label={tile.entryTitle} />))}
                        </Wrapper>
                    </Hidden>
                    <Hidden smUp>
                        
                    </Hidden> */}
                    <Wrapper value={value} handleChange={handleChange} aria-label="scrollable tabs" classes={classes.tabs}>
                        {props.tiles.map((tile, i) => (<Tab disableTouchRipple style={{ textTransform: "none" }} key={i} label={tile.entryTitle} {...a11yProps(i)} />))}
                    </Wrapper>
                    {
                        props.tiles.map((tile, i) => {
                            var componentType = returnTypename(tile.__typename);
                            var Component = Components[componentType]
                            return (
                                <TabPanel style={{borderRadius: !!value ? "10px": "0 10px 10px 10px" }} className="tabpanel" value={value} index={i}>
                                    <Component {...tile} />
                                </TabPanel>
                            )
                        })
                    }
                </TabContainer>
            </Grid>
        </StyledGridContainer>
    );
}