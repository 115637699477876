import React from 'react'
import { useField } from 'formik';
import { TextField } from "@material-ui/core";

const MyTextAreaField = ({ placeholder, variant, className, ...props }) => {
    const [field, meta] = useField(props)
    const errorText = meta.error && meta.touched ? meta.error : ""
    return (
        <TextField error={!!errorText} multiline rows={4} className={className} placeholder={placeholder} variant={variant} {...field} helperText={errorText} />
    )
}

export default MyTextAreaField