import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as Components from "../components"
import { Grid } from "@material-ui/core";
import { returnTypename, returnColumns } from "helper"
import styled from "styled-components";
import "./Accordion.scss";

const Faqs = styled.div`
    padding-bottom: 15px;
    .MuiAccordion-rounded {
        box-shadow: none;
    }
    .MuiAccordionSummary-content.Mui-expanded {
        p {
            color: white;
        }
        h5 {
            color: white; 
        }
    }
    .MuiAccordionSummary-expandIcon {
        color: #9055A7;  
    }
    .MuiAccordionSummary-expandIcon.Mui-expanded {
        color: white;
    }
    h5 {
        margin-bottom: 0;
    }
    .MuiAccordionDetails-root {
        overflow-wrap: break-word;
        word-break: break-word;
        background-color: #ffffff;
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0 0 10px 10px;
        padding: 25px;
        text-align: left;
        flex-direction: column;
    }
    .slick-prev {
        left: -15px;
    }
    .slick-next {
        right: -15px;
    }
    .slick-dots {
        display: none !important;
    }
    a {
        color: #9055A7;
        &:hover {
            color: #217ebe;
            border-bottom: 2px solid #217ebe;
        }
    }
    /* ipad Pro */
    @media (max-width: 1024px) { 
        padding: 0 15px 10px 15px;
    }
    /* ipad */
    @media (max-width: 768px) {
    
    }
    /* iphone */
    @media (max-width: 767px) { 
        padding: 0 15px 10px 15px;
    }
`

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

const Wrapper = styled(Grid)`
    max-width: 1060px !important;
`

export default function SimpleAccordion(props) {
    const classes = useStyles();
    var columns = returnColumns(props)
    var gridClass = props.gridClass ? props.gridClass : ""

    return (
        <Wrapper container className={gridClass}>
            <Grid item {...columns}>
                <div className={classes.root}>
                    {
                        props.tiles.map((tile, i) => {
                            var componentType = returnTypename(tile.__typename);
                            var Component = Components[componentType]
                            return (
                                <Faqs key={i}>
                                    <Accordion>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                            <h5>{tile.entryTitle}</h5>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Component onlyContent={true} index={i} {...tile} />
                                        </AccordionDetails>
                                    </Accordion>
                                </Faqs>
                            )
                        })}
                </div>
            </Grid>
        </Wrapper >
    );
}
