import React from 'react'
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const CookieBanner = styled.div`
    right: 0;
    position: fixed;
    bottom: 0;
    z-index: 11;
    width: auto;
    border-radius: 15px 0 0 0;
    box-shadow: rgba(0,0,0,.8) 0px 5px 40px;
    padding: 15px 40px;
    display: inline-flex;
    p {
        line-height: 24px;
        margin: 0 10px;
        font-size: 16px;
    }
    a {
        color: #ffffff;
        text-decoration: underline;
    }
    .privacybtn {
        text-align: end;
        .btn1 {
            margin-top: 5px;
            margin-bottom: 5px;
            padding: 5px;
            max-width: 125px;
            &:hover {
                cursor: pointer;
            }
        }
        
    }
    @media (max-width: 1024px) {
        display: block; 
        .privacybtn {
            text-align: center;
        }
    }
    @media (max-width: 960px) {
        display: block;
        border-radius: 15px 15px 0 0;
        .privacybtn {
            text-align: center;
        }
    }
    @media (max-width: 600px) {
        display: block;
        margin: 0 15px;
        max-width: 100%;
        bottom: 45px;
        border-radius: 15px;
        padding: 25px 15px 0 15px;
        p {
            font-size: 16px;
        }
        .privacybtn {
            padding-top: 0;
            text-align: center;
            .btn1 {
                margin-top: 10px; 
            }
        }
    }
`

export default ({ cookieNotice }) => {
    const [showCookie, setShowCookie] = React.useState(true)
    const cookieName = "cookieAccepted"
    const setCookie = () => {
        if (typeof window !== undefined && typeof document !== undefined) {
            document.cookie = `${cookieName}=1`
            setShowCookie(false)
        }
    }

    const getCookie = () => {
        if (typeof window !== undefined && typeof document !== undefined) {
            var [cookie] = decodeURIComponent(document.cookie)
                .split(";")
                .filter((curr) => curr.includes(cookieName));
            if(cookie && parseInt(cookie.split("=")[1])) {
                setShowCookie(false)
            }
        }
    }

    React.useEffect(() => {
        getCookie()
    })

    if (showCookie) {
        return (
            <CookieBanner className="bg-dark-blue">
                <div>
                {documentToReactComponents(cookieNotice.json)}
                </div>
                <div className="privacybtn">
                    <button onClick={setCookie} className="btn1">Ok</button>
                    {/* <a href="#">Change My Preference</a> */}
                </div>
            </CookieBanner>
        )
    }
    else {
        return <></>
    }
}