import React from "react"
import { ComponentLink } from "../components"
import { Grid } from "@material-ui/core"
import Hidden from '@material-ui/core/Hidden';
import styled from 'styled-components';
import "./CTA.scss";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

const Sticky = styled(Grid)`
    display: block;
    position: fixed;
    bottom: 0;
    z-index: 99;
    left: 5px;
    right: 5px;
    text-align: center;
`

const BookOnline = styled.a`
    top: 150px;
    right: 0;
    display: inline-block;
    border-bottom-left-radius: 45px;
    border-top-left-radius: 45px;
    padding: 20px 20px 20px 25px;
    font-size: 17px;
    position: fixed;
    z-index: 1000;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.5);
    &:before {
        content: "";
        position: absolute;
        width: 95px;
        height: 3px;
        background-color: #ffffff;
        visibility: hidden;
        overflow: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all .5s ease-in-out 0;
        transition: all .25s ease-in-out 0s;
        left: 65px;
        z-index: 9;
        bottom: 21px;
        overflow: hidden;
    }
    &:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(0.);
    }
`

const BookOnlineContainer = styled.div`
    display: table;
    > * {
        display: table-cell;
        vertical-align: middle;
    }
`

const BookOnlineLabel = styled.div`
    background-image: linear-gradient($heading-underline,$heading-underline);
    background-size: 0 3px,auto;
    background-repeat: no-repeat;
    background-position: bottom;
    transition: all .25s ease-in-out 0s;
    max-width: 120px;
    p {
        font-family: 'Gilroy';  
        font-weight: 500;
        margin: 0;
        line-height: 23px;
    }
`

const BookOnlineImage = styled.img`
    height: 25px;
    margin-right: 15px;
`

export default (props) => {
    var phoneNumber = props.phoneNumber
    delete phoneNumber.image
    delete phoneNumber.imageOnHover
    var { image, imageOnHover, ...bookAppointmentLink } = props.bookAppointmentLink

    return <>
        <Hidden xsDown>
            <BookOnline className="bookOnline" href={url(props.bookAppointmentLink.targetContent)} aria-label={"(opens in a new tab)"} style={{ display: "block" }} rel="noreferrer">
                <BookOnlineContainer>
                    <div>
                        <BookOnlineImage className="bookOnlineIcon" src={image.imageDesktopView.file.url} alt={image.imageDesktopView.title} />
                    </div>
                    <BookOnlineLabel>
                        <p>{props.bookAppointmentLink.linkText}</p>
                    </BookOnlineLabel>
                </BookOnlineContainer>
            </BookOnline>
        </Hidden>
        <Hidden smUp>
            <Grid container>
                <Sticky className="mob-sticky">
                    <button><ComponentLink {...phoneNumber} /></button>
                    <button><ComponentLink {...bookAppointmentLink} /></button>
                </Sticky>
            </Grid>
        </Hidden>
    </>
}

function url(targetContent) {
    if (typeof targetContent === "object") {
        return documentToPlainTextString(targetContent.json ? targetContent.json : targetContent)
    }
    return targetContent
}