import React from "react"
import { Grid, Hidden } from "@material-ui/core"
import { ComponentContent, ComponentImage } from "../components"
import styled from "styled-components";
import "./ComponentImageWContent.scss";
import { makeStyles } from "@material-ui/core/styles"

const GridSize = {
    "Extra Large": [[12, 12], [12, 12], [12, 12]],
    "Large": [[12, 12], [12, 12], [4, 8]],
    "Medium": [[12, 12], [12, 12], [6, 6]],
    "Small": [[12, 12], [12, 12], [8, 4]],
    "Extra Small": []
}

const Content = styled(ComponentContent)`
    width: calc(100% - 190px);
    display: inline-block;
    vertical-align: top;
`
const Tile = styled(Grid)`
    margin-bottom: 20px !important;
    padding: 30px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
    p {
        color: #363636;
    }
    /* ipad Pro */
    @media (max-width: 1024px) { 
        margin: 0 15px 20px !important;
    }

    /* ipad */
    @media (max-width: 768px) {
        
    }
    /* iphone */
    @media (max-width: 767px) { 
        margin: 0 15px 20px !important;
        padding:0;
    }
`

const Image = styled.img`
    object-fit: contain;
    width: 100px;
    ${props => props.imagePosition === "Right" ? "padding-left: 30px;" : "padding-right: 30px;"}
`

const ImageMobile = styled.img`
    padding-top: 30px;
`
const directionMap = {
    "Right": "left",
    "Left": "right"
}

const StyledImageContainer = styled.div`
    ${props => ["Right", "Left"].includes(props.imagePosition) ? (`padding-${directionMap[props.imagePosition]}: 20px`) : ""}
    ${props => ["Top", "Bottom"].includes(props.imagePosition) ? (`padding: 0 10px`) : ``}
`

const Wrapper = styled(Grid)`
    max-width: 1060px;
    ${props => ["Right", "Left"].includes(props.imagePosition) ? (`padding-${directionMap[props.imagePosition]}: 20px`) : ``}
    ${props => ["Top", "Bottom"].includes(props.imagePosition) ? (`padding: 0 10px;`) : ``}
`

const useStyles = makeStyles({
    root: (props) => ["Right", "Left"].includes(props.imagePosition) && props.gridProps && !props.gridProps.isLastChild ? {
        marginBottom: "0 !important",
        '@media (max-width: 600px)': {
            marginBottom: "20px !important"
        }
    } : {}
})

export default (p) => {
    var { imagePosition, content, image, textOverlaid, imageSize, ...props } = p
    var direction = "row"
    if (imagePosition === "Top") {
        direction = "column"
    }
    else if (imagePosition === "Right") {
        direction = "row-reverse"
    }
    else if (imagePosition === "Bottom") {
        direction = "column-reverse"
    }
    if (imageSize === "Extra Small") {
        return (
            <Grid container alignItems="center" >
                <Hidden xsDown>
                    <Tile className="bg-blue" direction={direction} container item xs={12} justify="center" alignItems="center">
                        <Image imagePosition={imagePosition} src={image.imageDesktopView ? image.imageDesktopView.file.url : ""} alt={image.entryTitle} />
                        <Content gridProps={props.gridProps} content={content} />
                    </Tile>
                </Hidden>
                <Hidden smUp>
                    <Tile direction="column" container item xs={12} justify="center" alignItems="center">
                        <ImageMobile src={image.imageDesktopView ? image.imageDesktopView.file.url : ""} alt={image.entryTitle} />
                        <ComponentContent gridProps={props.gridProps} content={content} />
                    </Tile>
                </Hidden>
            </Grid>
        )
    }
    var opt = GridSize[imageSize]
    var addPadding = ["Right", "Left"].includes(imagePosition);
    var align = (textOverlaid ? "center" : "none");
    const styles = useStyles(p);
    return (
        <Wrapper
            container
            direction={direction}
            alignItems={align}
            classes={{root: styles.root}}
        >
            <Grid item xs={opt[0][1]} sm={opt[1][1]} md={opt[2][1]}>
                <StyledImageContainer imagePosition={imagePosition} className="styledContainerImage">
                    <ComponentImage link={true} {...image} />
                </StyledImageContainer>
            </Grid>
            <Grid item xs={opt[0][0]} sm={opt[1][0]} md={opt[2][0]}>
                {
                    textOverlaid ?
                        (
                            <div className="styledContainer">
                                <ComponentContent gridProps={props.gridProps} imagePosition={imagePosition} isImage={addPadding} content={content} />
                            </div>
                        )
                        : (
                            <ComponentContent gridProps={props.gridProps} imagePosition={imagePosition} isImage={addPadding} content={content} />
                        )
                }
            </Grid>
        </Wrapper>
    )
}