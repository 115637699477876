import React from 'react';
import { Grid, Hidden } from '@material-ui/core';

export default ({ link, ...props }) => {
    if(props.height) {
        if(!props.style) {
            props.style = {}
        }
        props.style = { height: props.height }
    }
    // props.style.height = (props.height ? props.height : "auto");
    if (link) {
        return <Image {...props} />
    }
    return (
        <Grid item xs={12} sm={12} md={12} className="componentImage">
            <Image {...props} />
        </Grid>
    );
}

const Image = ({ style, imageDesktopView, imageTabletView, imageMobileView, entryTitle }) => (
    <>
        <Hidden smDown>
            <img style={style} src={imageDesktopView ? imageDesktopView.file.url : ""} alt={entryTitle} />
        </Hidden>
        <Hidden mdUp xsDown>
            <img style={style} src={imageTabletView ? imageTabletView.file.url : ""} alt={entryTitle} />
        </Hidden>
        <Hidden smUp>
            <img style={style} src={imageMobileView ? imageMobileView.file.url : ""} alt={entryTitle} />
        </Hidden>
    </>
)